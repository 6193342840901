html {
  position: relative;
  min-height: 100%;
}

body {
  padding: 0;
}

/* Bootstrap stuff */
.navbar-brand, .nav-item {
  border-bottom: 0;
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
